import { MultiSelect, OrderByType } from '@LoopKitchen/loop-ui'
import { FileDownloadOutlined } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Button, Stack, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Tooltip from '@mui/material/Tooltip'
import { get } from 'lodash'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import CustomMaterialTableWrapper from 'src/components/CustomMaterialTableWrapper/CustomMaterialTableWrapper'
import { AccountingPushHistoryFilters, DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import Page from '../../../components/mui/Page'
import { useErrorData } from '../../../context/ErrorContext'
import { useFilter } from '../../../context/FilterContext'
import { useSnackData } from '../../../context/SnackContext'
import usePostHogHook from '../../../utils/hooks/usePostHogHook'

export enum JournalPushStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'IN_PROGRESS',
  SKIPPED = 'SKIPPED'
}

interface AccountingPushLog {
  b_name: string
  chain: string
  dummy: boolean
  start_date: string
  end_date: string
  error: string
  push_timestamp: string
  status: JournalPushStatus
  updated_at: string
  company_name: string
  integration_type: string
  csv_link: string
}

export default function AccountingHistory() {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const { openSuccess, openError, setDownloadSnack } = useSnackData()
  const { trackPostHogDownload } = usePostHogHook()
  const { headerHeightPx } = useLayoutDimension()
  const [loading, setLoading] = useState<boolean>(true)

  const [data, setData] = useState<AccountingPushLog[]>([])
  const [visibleData, setVisibleData] = useState<AccountingPushLog[]>([])
  const [selectedStatus, setSelectedStatus] = useState<JournalPushStatus[]>(Object.values(JournalPushStatus))
  const [dummy, setDummy] = useState(false)
  const [sortBy, setSortBy] = useState('updated_at')
  const [orderBy, setOrderBy] = useState<OrderByType>('descending')

  const getCSVString = async () => {
    const filtersForQuery = getFiltersV2(['chain', 'b_name', 'b_name_id', 'start_date', 'end_date'], true)
    const requestBody = {
      chain: filtersForQuery.chain_in,
      platforms: filtersForQuery.vb_platform_in,
      b_name: filtersForQuery.b_name_in,
      b_name_id: filtersForQuery.b_name_ids_in,
      start_date: filtersForQuery.start_date_in,
      end_date: filtersForQuery.end_date_in,
      dummy: dummy
    } as AccountingPushHistoryFilters

    return await DefaultService.getPushHistoryAccountingJournalEntriesPushHistoryPost(requestBody, true, !window.location.href.endsWith('internal'))
  }

  const getData = async (initialLoading: boolean) => {
    setLoading(true)
    try {
      const filtersForQuery = getFiltersV2(['chain', 'b_name', 'b_name_id', 'start_date', 'end_date'], true)
      const requestBody = {
        chain: filtersForQuery.chain_in,
        b_name: filtersForQuery.b_name_in,
        b_name_id: filtersForQuery.b_name_ids_in,
        start_date: filtersForQuery.start_date_in,
        end_date: filtersForQuery.end_date_in,
        dummy: dummy
      } as AccountingPushHistoryFilters

      const result = await DefaultService.getPushHistoryAccountingJournalEntriesPushHistoryPost(requestBody, false, !window.location.href.endsWith('internal'))
      initialLoading
        ? setData(result ? result : [])
        : setData((prev) => {
            return {
              ...result,
              data: [...prev, ...result]
            }
          })
    } catch (err) {
      handleError(err.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData(true)
  }, [...getFilters(['chain', 'b_name', 'start_date', 'end_date']), dummy])

  useEffect(() => {
    setVisibleData(
      data
        .filter((a) => selectedStatus.includes(a.status))
        .sort((a, b) => {
          if (orderBy === 'ascending') return get(a, sortBy, null) < get(b, sortBy, null) ? -1 : 1
          else return get(a, sortBy, null) < get(b, sortBy, null) ? 1 : -1
        })
    )
  }, [data, selectedStatus, orderBy, sortBy])

  return (
    <Page
      title="Accounting Push History"
      filterProps={{
        hideFilterKeys: ['vb_name', 'am_name', 'vb_platform'],
        hideResetButton: true,
        customMaxDate: moment().format('YYYY-MM-DD'),
        extraFilters: [
          <MultiSelect
            label="Status"
            selectButtonSx={{
              p: '12px 32px 12px 8px'
            }}
            disableScrollLock
            options={Object.values(JournalPushStatus).map((s) => ({ label: s, value: s }))}
            value={selectedStatus}
            onChange={(newValue) => {
              if (Array.isArray(newValue)) {
                setSelectedStatus(newValue)
              }
            }}
          />
        ]
      }}>
      <Box sx={{ px: 3, pt: 3, background: 'white' }}>
        <CustomMaterialTableWrapper
          titleComp={
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={2}>
              <Typography
                fontWeight={500}
                paddingRight="10px">
                JOURNAL PUSHES
              </Typography>
              <FormControlLabel
                label="Test Pushes"
                control={
                  <Switch
                    checked={dummy}
                    onChange={(event, checked) => setDummy(checked)}
                  />
                }
              />
            </Stack>
          }
          data={visibleData}
          isLoading={loading}
          columns={[
            {
              title: 'Location',
              field: 'b_name',
              sortable: true,
              render: (data) => {
                return (
                  <Typography variant="subtitle2">
                    <b>{get(data, 'b_name', '')}</b>
                  </Typography>
                )
              }
            },
            {
              title: 'Start Date',
              field: 'start_date',
              sortable: true,
              render: (data) => {
                return (
                  <Typography variant="subtitle2">
                    <b>{moment(data.start_date).format('MMM D, YYYY')}</b>
                  </Typography>
                )
              }
            },
            {
              title: 'End Date',
              field: 'end_date',
              sortable: true,
              render: (data) => {
                return (
                  <Typography variant="subtitle2">
                    <b>{moment(data.end_date).format('MMM D, YYYY')}</b>
                  </Typography>
                )
              }
            },
            {
              title: 'Status',
              field: 'status',
              render: (data) => {
                return (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems={'end'}>
                    <Typography variant="subtitle2">
                      <b>{data.status}</b>
                    </Typography>
                    {data.status === JournalPushStatus.FAILURE && (
                      <Tooltip
                        arrow
                        placement="right"
                        componentsProps={{
                          tooltip: {
                            style: {
                              color: 'white',
                              fontSize: 14,
                              fontWeight: 500,
                              background: 'black',
                              maxWidth: 400,
                              padding: '10px'
                            }
                          },
                          arrow: { style: { color: 'black' } }
                        }}
                        title={data.error}>
                        <InfoIcon sx={{ color: 'rgba(0,0,0,0.3)', width: '20px' }} />
                      </Tooltip>
                    )}
                  </Stack>
                )
              }
            },
            {
              title: 'Company',
              field: 'company_name',
              sortable: true,
              render: (data) => {
                return (
                  <Typography variant="subtitle2">
                    <b>
                      {data.company_name} {data.integration_type && `[${data.integration_type.toUpperCase()}]`}
                    </b>
                  </Typography>
                )
              }
            },
            {
              title: 'Attempted At',
              field: 'updated_at',
              sortable: true,
              render: (data) => {
                return <Typography variant="subtitle2">{moment.utc(data.updated_at).local().format('h:mm A MMM D, YYYY')}</Typography>
              }
            },
            {
              title: 'Download',
              field: 'csv_link',
              hideInExport: true,
              render: (data) => {
                return data?.csv_link ? (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<FileDownloadOutlined />}
                    onClick={async () => {
                      setDownloadSnack({ status: 'start' })
                      try {
                        const refreshedUrl = await DefaultService.getSignedUrlGcpSignedUrlGet(data?.csv_link)

                        if (!refreshedUrl) throw new Error('No report found')

                        let a = document.createElement('a')
                        a.download = data.b_name
                        a.href = refreshedUrl
                        a.click()
                        setDownloadSnack({ status: 'complete' })
                      } catch (err) {
                        setDownloadSnack({ status: 'close' })
                        handleError(err.message || "Couldn't download report")
                      }
                    }}>
                    Download
                  </Button>
                ) : null
              }
            }
          ]}
          options={{
            stickyHeader: headerHeightPx,
            export: true,
            csvFileName: 'push_history.csv',
            pagination: true,
            sortable: true,
            showOrderBy: true,
            initialOrderBy: 'descending',
            totalPaginatedDataLength: visibleData?.length,
            extraExportColumns: [
              {
                title: filterNames.vb_platform,
                field: 'platforms'
              },
              {
                title: 'Debit',
                field: 'debit'
              },
              {
                title: 'Credit',
                field: 'credit'
              },
              {
                title: 'Latest debit',
                field: 'latest_debit'
              },
              {
                title: 'Latest credit',
                field: 'latest_credit'
              }
            ]
          }}
          onOrderByChange={(type) => setOrderBy(type)}
          onSortByChange={(selectedOption) => setSortBy(selectedOption.value)}
          // onLastPage={() => {
          //   if (data.next_offset) {
          //     getData(false)
          //   }
          // }}
          onExportModifyCSV={getCSVString}
          onExportCSV={async () => {
            setDownloadSnack({ status: 'start' })
            try {
              const res = await getCSVString()

              trackPostHogDownload({
                fileName: 'push_history',
                type: 'CSV'
              })
              let blob = new Blob([res], { type: 'text/csv' })
              let url = URL.createObjectURL(blob)
              let a = document.createElement('a')
              a.download = 'push_history'
              a.href = url
              a.click()
              setDownloadSnack({ status: 'complete' })
              downloadSuccessSlackAlert({ title: 'JOURNAL PUSHES', apiUrl: '/accounting/journal_entries/push/history' })
            } catch (err) {
              setDownloadSnack({ status: 'close' })
              openError(err.message)
              downloadFailedSlackAlert({ err, apiUrl: '/accounting/journal_entries/push/history' })
            }
          }}
        />
      </Box>
    </Page>
  )
}

// NEW DESIGN
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded'
import { Box, Skeleton, SxProps, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { formatNumber } from 'src/utils/functions'

export interface DeltaChipProps {
  delta: number
  deltaType: 'success' | 'error' | 'zero'
  tooltipTitle?: React.ReactNode
  loading?: boolean
  deltaSx?: SxProps
  reverse?: boolean
}

export default function DeltaChip(props: DeltaChipProps) {
  const { delta, deltaType, tooltipTitle, loading, deltaSx, reverse } = props
  let deltaDownColors = {
    // background: '#FFE5E5',
    background: '#FFE5E5',
    color: '#e52e2e'
  }
  let deltaUpColors = {
    // background: '#E1FFB1',
    background: '#DAF2E4',
    color: '#0e8c43'
  }
  const deltaZero = {
    background: 'rgba(128,128,128,0.25)',
    color: '#000'
  }

  const colorObj = React.useMemo(() => {
    if (deltaType === 'error') {
      return deltaDownColors
    } else if (deltaType === 'success') {
      return deltaUpColors
    } else {
      return deltaZero
    }
  }, [deltaType])

  return (
    <>
      {(loading || typeof delta === 'number') && (
        <Tooltip title={tooltipTitle}>
          <Box
            display="inline-flex"
            alignItems={'center'}
            gap="0.3rem"
            sx={{
              // background: colorObj.background,
              padding: '0.15rem 0.5rem',
              ...deltaSx
              // borderRadius: '5rem'
            }}>
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                bgcolor: colorObj.background
              }}>
              {loading && (
                <Skeleton
                  variant="circular"
                  width={20}
                  height={20}
                />
              )}
              {!loading && delta < 0 && (
                <ArrowDownwardRoundedIcon sx={{ color: colorObj.color, fontSize: '14px', transform: reverse ? 'rotate(180deg)' : 'rotate(0deg)' }} />
              )}
              {!loading && delta > 0 && (
                <ArrowDownwardRoundedIcon sx={{ color: colorObj.color, fontSize: '14px', transform: reverse ? 'rotate(0deg)' : 'rotate(180deg)' }} />
              )}
            </Box>
            {loading ? (
              <Skeleton
                variant="text"
                width="25px"
              />
            ) : (
              <Typography
                variant="subtitle2"
                sx={{
                  color: colorObj.color,
                  fontSize: '0.6rem'
                }}>
                {formatNumber(delta, { maxFractionDigits: 2 })}%
              </Typography>
            )}
          </Box>
        </Tooltip>
      )}
    </>
  )
}

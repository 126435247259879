import workerCreator from '../workerCreator'

type ColumnType = { title: string; field: string; hidden?: boolean }

export default function processCSV(obj: {
  csvData: string | { [key: string]: string | number }[]
  columns: ColumnType[]
  onSuccess: (obj: { headers: string[]; rows: string[][] }) => void
  onError: (errMsg: string) => void
}) {
  const { csvData, columns, onSuccess, onError } = obj
  let worker = workerCreator((e) => {
    const { csvData, columns } = e.data as { csvData: typeof obj.csvData; columns: ColumnType[] }
    try {
      if (!csvData) {
        throw new Error('CSV data is empty')
      }
      const visibleColumns = columns.filter((column) => !column.hidden)

      const maxRecords = 100000

      if (typeof csvData === 'string') {
        // @ts-ignore
        const tempRows = Papa.parse(csvData).data

        const rows = tempRows.length > maxRecords ? tempRows.slice(0, maxRecords) : tempRows

        const originalHeader = rows.shift()

        const tempHeaders = originalHeader.map((header) => {
          return visibleColumns.find((col) => col.field === header)?.title || null
        })

        const headerIndexesNotInColumns = tempHeaders.reduce((acc, curr, index) => {
          if (!curr) {
            acc.push(index)
          }
          return acc
        }, [] as number[])

        const headers = tempHeaders.filter((_, index) => !headerIndexesNotInColumns.includes(index))

        const data =
          headerIndexesNotInColumns.length === 0
            ? rows
            : rows.map((values) => {
                const result: typeof values = []
                values.forEach((value, index) => {
                  if (!headerIndexesNotInColumns.includes(index)) {
                    result.push(value)
                  }
                })
                return values.filter((_, index) => !headerIndexesNotInColumns.includes(index))
              })

        this.postMessage({ success: true, data: { headers, rows: data } })
      } else {
        const headers = visibleColumns.map((col) => col.title)
        const tempCsvData = csvData.length > maxRecords ? csvData.slice(0, maxRecords) : csvData
        const data = tempCsvData.map((row) => {
          return visibleColumns.map((col) => {
            return row[col.field]
          })
        })

        this.postMessage({ success: true, data: { headers, rows: data } })
      }
    } catch (err) {
      this.postMessage({ success: false, error: err.message })
    }
  })

  worker.postMessage({ csvData, columns })

  worker.onmessage = (e) => {
    const { success, data, error } = e.data as { success: boolean; data: { headers: string[]; rows: string[][] }; error: string }

    if (success) {
      onSuccess(data)
    } else {
      onError(error)
    }

    worker.terminate()
    worker = null
  }
}

import { ChevronRight } from '@mui/icons-material'
import { Box, SxProps, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useFilter } from 'src/context/FilterContext'
import { usePayoutsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/PayoutsBreakdownDataContext'
import { useTransactionsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/TransactionsBreakdownDataContext'
import { PLATFORM } from 'src/services/openApi'
import { Modules } from 'src/utils/config/config'
import { Dictionary } from 'src/utils/config/dictionary'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import DataComp from '../../components/DataComp'
import { indicatorColor, rowFlex } from '../../utils/constants'

interface TotalBreakdownProps {
  sx?: SxProps
}

const TotalBreakdown = React.forwardRef<HTMLElement, TotalBreakdownProps>((props, componentRef) => {
  const { sx } = props
  const navigate = useNavigate()
  const { getAllPossibleFilters } = useFilter()
  const { breakdownData, breakdownLoading } = useTransactionsBreakdownDataContext()
  const { fontSizes } = usePayoutsBreakdownDataContext()

  const otherTotal = React.useMemo(() => {
    return (
      get(breakdownData, 'values.customer_tip', 0) +
      get(breakdownData, 'values.restaurant_fees', 0) +
      get(breakdownData, 'values.miscellaneous', 0) +
      get(breakdownData, 'values.unaccounted', 0)
    )
  }, [breakdownData])

  return (
    <Box
      ref={componentRef}
      sx={{ ...sx }}>
      <DataComp
        sx={{ border: 'none', py: 0, mt: '15px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { pb: 1 },
            cols: [
              {
                label: 'Component',
                flex: rowFlex[0],
                sx: { fontWeight: 400, fontSize: fontSizes['12'] }
              },
              {
                label: 'Total',
                flex: rowFlex[2],
                sx: { textAlign: 'right', fontWeight: 600, fontSize: fontSizes['12'] }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)' }}
        rows={[
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: [
              {
                label: 'Sales Including Tax',
                flex: rowFlex[0],
                sx: { py: 0, fontWeight: 600, color: 'white', fontSize: fontSizes['14'] },
                tooltip: 'Total revenue generated from orders, including taxes charged to customers.',
                tooltipColor: 'rgba(255, 255, 255, 0.6)'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.subtotal_with_tax', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.subtotal_with_tax', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: {
                  py: 0,
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { pt: '10px' },
            cols: [
              {
                label: 'Sales Excluding Tax',
                flex: rowFlex[0],
                sx: { py: 0, color: 'rgba(255, 255, 255, 0.5)', fontSize: fontSizes['14'], fontWeight: 400 },
                tooltip: 'Total revenue generated from orders, excluding any taxes charged to customers.',
                tooltipColor: 'rgba(255, 255, 255, 0.6)'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.subtotal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.subtotal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  py: 0,
                  color: 'rgba(255, 255, 255, 0.5)',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { py: '3px' },
            cols: []
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            sx: {
              pb: '10px',
              borderBottom: '1px solid #E6E6E6E6'
            },
            hideIndicator: true,
            cols: [
              {
                label: Modules.LostSales,
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontWeight: 400, fontSize: fontSizes['14'] },
                tooltip: `Potential revenue lost due to ${Dictionary.CANCELLED.toLowerCase()} or failed orders.`
              },
              {
                label: (
                  <Typography
                    sx={{
                      fontSize: fontSizes['22'],
                      lineHeight: 'initial',
                      color: 'rgba(47, 74, 90, 0.6)',
                      fontWeight: 600
                    }}>
                    {`${formatCurrency(get(breakdownData, 'values.lost_sales', 0), { maxFractionDigits: 2 })}`}
                  </Typography>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: {
              mt: '10px'
            },
            indicatorColor: indicatorColor.refunds,
            cols: [
              {
                label: Modules.UnfulfilledRefunds,
                flex: rowFlex[0],
                tooltip: 'Refunds due to order unfulfillment.',
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.unfulfilled_refunds', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.unfulfilled_refunds', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.taxes,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', pb: '10px' },
            cols: [
              {
                label: 'Taxes',
                flex: rowFlex[0],
                tooltip: 'Taxes collected from customers and paid to you by 3P platforms.',
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.tax_host', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.tax_host', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: {
              mt: '10px',
              borderRadius: '8px',
              border: '1px solid #E6E6E6E6',
              py: '10px',
              pl: '20px'
            },
            hideIndicator: true,
            cols: [
              {
                label: 'Taxes Withheld',
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: fontSizes['14'], fontWeight: 400 },
                tooltip: 'Taxes collected from customers and held by 3P platforms to pay to tax authorities on your behalf.'
              },
              {
                label: (
                  <>
                    <span
                      style={{
                        color: 'rgba(47, 74, 90, 0.6)',
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: '175%'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.tax_witheld', 0), { maxFractionDigits: 2 })}
                    </span>
                  </>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.commissions,
            cols: [
              {
                label: 'Commissions',
                flex: rowFlex[0],
                tooltip: 'Fees charged by the platform for facilitating orders.',
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.commission', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.commission', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.restaurant_delivery_charge,
            cols: [
              {
                label: 'Restaurant Delivery Charge',
                flex: rowFlex[0],
                tooltip: 'Charges collected from customers for restaurant delivery.',
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(15, 120, 60, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.restaurant_delivery_charge', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      {getAllPossibleFilters(['vb_platform']).vb_platform?.some((platform) => platform === PLATFORM.OLO) && get(breakdownData, 'values.loyalty', 0) !== 0 ? (
        <DataComp
          sx={{ mt: '12px' }}
          rows={[
            {
              sx: {
                borderRadius: '8px',
                py: '15px',
                pl: '20px',
                pr: '10px',
                border: '1px solid #E3EDF0',
                mb: '10px',
                bgcolor: '#E3EDF0',
                cursor: 'pointer'
              },
              onClick: () => navigate('/marketing/trends'),
              indicatorColor: indicatorColor.marketing,
              cols: [
                {
                  label: 'Marketing',
                  flex: rowFlex[0],
                  tooltip: 'Costs associated with promoting the restaurant on the platform.',
                  sx: { fontSize: fontSizes['14'] }
                },
                {
                  label: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'row-reverse',
                        gap: '10px'
                      }}>
                      <ChevronRight sx={{ color: '#196E82' }} />
                      <Typography
                        sx={{
                          fontSize: fontSizes['22'],
                          fontWeight: 600,
                          lineHeight: 'initial',
                          color: 'rgba(241, 84, 18, 1)'
                        }}>
                        -{formatCurrency(Math.abs(get(breakdownData, 'values.marketing', 0)), { maxFractionDigits: 2 })}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end">
                        <Typography
                          sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                          {formatNumber(get(breakdownData, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  loading: breakdownLoading,
                  flex: 'initial',
                  sx: { display: 'flex', justifyContent: 'flex-end' }
                }
              ]
            },
            {
              hideIndicator: true,
              indicatorColor: indicatorColor.loyalty,
              sx: { pt: '10px', borderTop: '1px solid #E6E6E6E6' },
              cols: [
                {
                  label: 'Loyalty',
                  flex: rowFlex[0],
                  sx: {
                    color: 'rgba(47, 74, 90, 0.6)',
                    fontWeight: 'normal',
                    fontSize: fontSizes['14']
                  },
                  tooltip: 'Specific to Olo, this is the cost of the loyalty program.'
                },
                {
                  label: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'row-reverse',
                        gap: '10px'
                      }}>
                      <Typography
                        sx={{
                          fontSize: fontSizes['22'],
                          fontWeight: 600,
                          lineHeight: 'initial',
                          color: 'rgba(241, 84, 18, 1)'
                        }}>
                        -{formatCurrency(Math.abs(get(breakdownData, 'values.loyalty', 0)), { maxFractionDigits: 2 })}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end">
                        <Typography
                          sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                          {formatNumber(get(breakdownData, 'percentages.loyalty', 0), { maxFractionDigits: 2 })}%
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  loading: breakdownLoading,
                  flex: 'initial',
                  sx: { display: 'flex', justifyContent: 'flex-end' }
                }
              ]
            }
          ]}
        />
      ) : (
        <DataComp
          sx={{ mt: '12px', bgcolor: '#E3EDF0', borderColor: '#E3EDF0', pr: '10px', cursor: 'pointer' }}
          rows={[
            {
              onClick: () => navigate('/marketing/trends'),
              indicatorColor: indicatorColor.marketing,
              cols: [
                {
                  label: 'Marketing',
                  flex: rowFlex[0],
                  tooltip: 'Costs associated with promoting the restaurant on the platform.',
                  sx: { fontSize: fontSizes['14'] }
                },
                {
                  label: (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        flexDirection: 'row-reverse',
                        gap: '10px'
                      }}>
                      <ChevronRight sx={{ color: '#196E82' }} />
                      <Typography
                        sx={{
                          fontSize: fontSizes['22'],
                          fontWeight: 600,
                          lineHeight: 'initial',
                          color: 'rgba(241, 84, 18, 1)'
                        }}>
                        -{formatCurrency(Math.abs(get(breakdownData, 'values.marketing', 0)), { maxFractionDigits: 2 })}
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-end">
                        <Typography
                          sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                          {formatNumber(get(breakdownData, 'percentages.marketing', 0), { maxFractionDigits: 2 })}%
                        </Typography>
                      </Box>
                    </Box>
                  ),
                  loading: breakdownLoading,
                  flex: 'initial',
                  sx: { display: 'flex', justifyContent: 'flex-end' }
                }
              ]
            }
          ]}
        />
      )}

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            sx: {
              borderRadius: '8px',
              py: '15px',
              pl: '20px',
              pr: '10px',
              border: '1px solid #E3EDF0',
              mb: '10px',
              bgcolor: '#E3EDF0',
              cursor: 'pointer'
            },
            onClick: () => navigate('/chargebacks/overview'),
            indicatorColor: indicatorColor.refunds,
            cols: [
              {
                label: Modules.Chargebacks,
                flex: rowFlex[0],
                tooltip: 'Refunds due to customer dissatisfaction.',
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <ChevronRight sx={{ color: '#196E82', fontSize: fontSizes['20'] }} />
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(241, 84, 18, 1)'
                      }}>
                      -{formatCurrency(Math.abs(get(breakdownData, 'values.chargeback', 0)), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.chargeback', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.reversals,
            sx: { pt: '10px', borderTop: '1px solid #E6E6E6E6' },
            cols: [
              {
                label: Modules.WonDisputes,
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['14']
                },
                tooltip: 'Amount reversed from previous transactions.'
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: 'rgba(14, 140, 67, 1)'
                      }}>
                      +{formatCurrency(get(breakdownData, 'values.reversal', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.reversal', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '12px' }}
        rows={[
          {
            indicatorColor: indicatorColor.unaccounted,
            sx: { pb: '5px' },
            cols: [
              {
                label: 'Others',
                flex: rowFlex[0],
                sx: { fontSize: fontSizes['14'] }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: otherTotal > 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)'
                      }}>
                      {otherTotal > 0 && '+'}
                      {formatCurrency(otherTotal, { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#196E821F', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(Math.abs((otherTotal * 100) / get(breakdownData, 'values.subtotal_with_tax', 0)), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),
                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          },
          {
            sx: { py: '5px' },
            cols: [
              {
                label: 'Customer Tips',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: 'Additional amounts paid by customers as tips for service.'
              },
              {
                label: '+' + formatCurrency(get(breakdownData, 'values.customer_tip', 0), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: fontSizes['16'],
                  color: 'rgba(14, 140, 67, 1)'
                }
              }
            ]
          },
          {
            sx: { py: '5px' },
            hide:
              !getAllPossibleFilters(['vb_platform']).vb_platform?.some((platform) => platform === PLATFORM.OLO) ||
              get(breakdownData, 'values.restaurant_fees', 0) === 0,
            cols: [
              {
                label: 'Restaurant Fees',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: 'Specific to Olo, this is the fees charged by the restaurant for facilitating orders.'
              },
              {
                label: '+' + formatCurrency(get(breakdownData, 'values.restaurant_fees', 0), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontSize: fontSizes['16'],
                  color: 'rgba(14, 140, 67, 1)'
                }
              }
            ]
          },
          {
            sx: { py: '5px' },
            cols: [
              {
                label: 'Miscellaneous',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: 'Various fees or charges that do not fit into specific categories.'
              },
              {
                label:
                  (get(breakdownData, 'values.miscellaneous', 0) >= 0 ? '+' : '-') +
                  formatCurrency(Math.abs(get(breakdownData, 'values.miscellaneous', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: get(breakdownData, 'values.miscellaneous', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: fontSizes['16']
                }
              }
            ]
          },
          {
            sx: { py: '5px' },
            cols: [
              {
                label: 'Unaccounted',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 'normal',
                  fontSize: fontSizes['12']
                },
                tooltip: 'Amounts that are not allocated or categorized, often requiring further investigation or clarification.'
              },
              {
                label:
                  (get(breakdownData, 'values.unaccounted', 0) >= 0 ? '+' : '-') +
                  formatCurrency(Math.abs(get(breakdownData, 'values.unaccounted', 0)), { maxFractionDigits: 2 }),
                flex: 'initial',
                loading: breakdownLoading,
                sx: {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  color: get(breakdownData, 'values.unaccounted', 0) >= 0 ? 'rgba(14, 140, 67, 1)' : 'rgba(241, 84, 18, 1)',
                  fontSize: fontSizes['16']
                }
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ bgcolor: 'rgba(47, 74, 90, 1)', mt: '12px', py: 3 }}
        rows={[
          {
            hideIndicator: true,
            cols: [
              {
                label: 'Estimated Payout',
                flex: rowFlex[0],
                sx: { fontSize: fontSizes['14'], color: 'white' }
              },
              {
                label: (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      flexDirection: 'row-reverse',
                      gap: '10px'
                    }}>
                    <Typography
                      sx={{
                        fontSize: fontSizes['22'],
                        fontWeight: 600,
                        lineHeight: 'initial',
                        color: '#fff'
                      }}>
                      {formatCurrency(get(breakdownData, 'values.total_payment', 0), { maxFractionDigits: 2 })}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="flex-end">
                      <Typography
                        sx={{ bgcolor: '#E3EDF0', color: '#196E82', p: '5px 8px', lineHeight: 'initial', borderRadius: '14px', fontSize: fontSizes['12'] }}>
                        {formatNumber(get(breakdownData, 'percentages.total_payment', 0), { maxFractionDigits: 2 })}%
                      </Typography>
                    </Box>
                  </Box>
                ),

                loading: breakdownLoading,
                flex: 'initial',
                sx: { display: 'flex', justifyContent: 'flex-end' }
              }
            ]
          }
        ]}
      />
    </Box>
  )
})

export default TotalBreakdown

import { Slack } from 'src/services'
import { DefaultService } from 'src/services/openApi'
import { LOOP_SUPPORT_EMAIL } from './config/config'

const slackService = new Slack()

interface slackMessage {
  title: string
  message: string
  channel?: string
  test?: boolean
}

export default function getData(procedureName, data) {
  return fetch(`https://api.loopapplication.xyz/run/query?procedure_name=data_visualization_metrics.${procedureName}`, {
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify(data),
    method: 'POST'
  })
}

const sendSlackNotification = async (
  slackMessageObject: slackMessage = {
    title: '',
    message: '',
    channel: 'fe-logs',
    test: false
  }
) => {
  try {
    if (process.env.NODE_ENV === 'production' || slackMessageObject.test) {
      return await slackService.postSlack({
        channel: 'frontend-alerts',
        ...slackMessageObject
      })
    }
  } catch (error) {
    console.error('Failed to send Slack notification:', error)
    // return await slackService.postSlack({
    //   ...slackMessageObject
    // })
  }
}

const sendSupportEmail = async (templateName: string, emailBody: Object, toEmails: string[] = [LOOP_SUPPORT_EMAIL], ccEmails: string[] = []) => {
  try {
    await DefaultService.triggerEmailDigestNotificationEmailDigestPost({
      template_name: templateName,
      from_email: LOOP_SUPPORT_EMAIL,
      to_emails: toEmails,
      cc_emails: ccEmails,
      params: {
        b_name_in: ''
      },
      extra_data: emailBody
    })
  } catch (error) {
    console.error('Failed to send support email:', error)
  }
}

export { sendSlackNotification, sendSupportEmail }


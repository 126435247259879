import { AttachMoney } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import Page from 'src/components/mui/Page'
import CardLayout from '../../components/CardLayout'
import { usePayoutsBreakdownDataContext } from '../../utils/context/PayoutsBreakdownDataContext'
import TransactionsBreakdownDataContextProvider from '../../utils/context/TransactionsBreakdownDataContext'
import TransactionsChartView from './Tabs/TransactionsChartView'

export default function Transactions() {
  const { fontSizes } = usePayoutsBreakdownDataContext()

  return (
    <Page title="Income Statement">
      <Box sx={{ px: 3, py: 4, bgcolor: 'white' }}>
        <Box
          display="flex"
          alignItems="center"
          gap="5px">
          <Box sx={{ color: 'black' }}>
            <AttachMoney
              color="inherit"
              sx={{ fontSize: fontSizes['24'] }}
            />
          </Box>
          <Box>
            <Typography
              color="rgba(28, 27, 31, 1)"
              fontSize={fontSizes['16']}
              fontWeight={600}>
              Overview
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: '15px' }}>
          <CardLayout />
        </Box>

        <Box sx={{ mt: '30px' }}>
          <TransactionsBreakdownDataContextProvider>
            <TransactionsChartView />
          </TransactionsBreakdownDataContextProvider>
        </Box>
      </Box>
    </Page>
  )
}

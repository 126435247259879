import { Column, CustomMaterialTable } from '@LoopKitchen/loop-ui'
import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { capitalize, get } from 'lodash'
import moment from 'moment'
import React from 'react'
import PlatformLogo from 'src/components/PlatformLogo'
import Page from 'src/components/mui/Page'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import { FiltersForQuery, OpenAPI } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import { formatNumber } from 'src/utils/functions'
import { downloadFailedSlackAlert, downloadSuccessSlackAlert } from 'src/utils/functions/slackSupport'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import { StandaloneType } from 'src/utils/hooks/useStandaloneRouteData'

type TableDataType = {
  data: { [key: string]: any }[]
  next_offset?: number
  max_rows?: number
}

interface StandaloneProps extends StandaloneType {
  title: string
}

export default function Standalone(props: StandaloneProps) {
  const {
    title,
    url,
    showFilters,
    apiMethod,
    exportUrl,
    sort: { enabled: sortEnabled, fields: sortableFields = [] },
    fields
  } = props
  const { openError, setDownloadSnack } = useSnackData()
  const { handleError } = useErrorData()
  const { getFiltersV2, getFilters } = useFilter()
  const { headerHeightPx } = useLayoutDimension()
  const [tableData, setTableData] = React.useState<TableDataType | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [orderBy, setOrderBy] = React.useState<string>('')
  const [ascending, setAscending] = React.useState(false)

  const dataKey = React.useMemo(() => {
    if (!tableData) {
      return undefined
    }

    const keys = Object.keys(tableData)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (Array.isArray(tableData[key])) {
        return key
      }
    }
    return undefined
  }, [tableData])

  const fieldObj = React.useMemo(() => {
    if (!fields || !Array.isArray(fields) || fields.length === 0) {
      return undefined
    }

    const obj: { [field: string]: (typeof fields)[0] } = {}

    fields.forEach((item) => {
      obj[item.field] = item
    })

    return obj
  }, [fields])

  const columns = React.useMemo(() => {
    if (!tableData || get(tableData, `${dataKey}.length`, 0) === 0) {
      return []
    }
    try {
      let result: Column<{ [key: string]: any }>[] = []

      const firstObj = get(tableData, `${dataKey}[0]`, {})

      const keys = Object.keys(fieldObj || firstObj)

      const tempFieldObj = fieldObj || {}

      keys.forEach((key, index) => {
        const title = key in filterNames ? filterNames[key] : key in tempFieldObj ? tempFieldObj[key].label : capitalize(key.replaceAll('_', ' '))

        const obj: (typeof result)[0] = {
          title,
          field: key,
          hidden: key in tempFieldObj ? tempFieldObj[key].hide : false,
          sortable: sortEnabled ? (sortableFields.length === 0 ? true : sortableFields.includes(key)) : false,
          headerTextStyle: {
            fontWeight: index === 0 ? 700 : 'normal'
          },
          textStyle: {
            fontWeight: index === 0 ? 700 : 'normal'
          }
        }
        if (typeof firstObj[key] === 'number') {
          obj['alignType'] = 'currency'
          obj['render'] = (data) => {
            try {
              return <Typography variant="subtitle2">{formatNumber(Number(data[key]))}</Typography>
            } catch (err) {
              return <Typography variant="subtitle2">{data[key]}</Typography>
            }
          }
        }
        if (['debit', 'credit'].includes(key.toLowerCase())) {
          obj['alignType'] = 'currency'
          obj['render'] = undefined
        }
        if (key.toLowerCase() === 'marketplace' || key.toLowerCase() === 'platform') {
          obj['alignType'] = 'center'
          obj['render'] = (data) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <PlatformLogo
                  platformName={data[key]}
                  height="15px"
                  width="auto"
                />
              </Box>
            )
          }
        }
        result.push(obj)
      })
      return result
    } catch (err) {
      console.error('columns: ', err)
      return []
    }
  }, [tableData, dataKey, fieldObj, sortEnabled, sortableFields])

  const filterNameArr = React.useMemo(() => {
    if (showFilters) {
      return ['b_name', 'vb_name', 'chain', 'am_name', 'vb_platform', 'start_date', 'end_date']
    }
    return ['chain', 'start_date', 'end_date']
  }, [showFilters])

  const { filterBody, filterArr } = React.useMemo(() => {
    if (showFilters) {
      return {
        filterBody: getFiltersV2(filterNameArr, true),
        filterArr: getFilters(filterNameArr)
      }
    }
    // let end_date = new Date()
    // let start_date = new Date(end_date)
    // start_date.setFullYear(start_date.getFullYear() - 1)
    const [chain, start_date, end_date] = getFilters(filterNameArr)
    return {
      filterBody: {
        b_name_in: '',
        vb_name_in: '',
        chain_in: chain,
        am_name_in: '',
        vb_platform_in: '',
        start_date_in: moment(start_date).format('YYYY-MM-DD'),
        end_date_in: moment(end_date).format('YYYY-MM-DD')
      } as FiltersForQuery,
      filterArr: [chain, start_date, end_date]
    }
  }, [showFilters, filterNameArr, ...getFilters(filterNameArr)])

  const onExportCSV = React.useCallback(async () => {
    if (!exportUrl) {
      return
    }
    try {
      setDownloadSnack({ status: 'start' })
      const res = await axios({
        url: exportUrl,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${OpenAPI.TOKEN as string}`
        },
        data: {
          ...filterBody
        }
      })
      let blob = new Blob([res.data], { type: 'text/csv' })
      let url = URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.download = title.replaceAll(' ', '_') + '.csv'
      a.href = url
      a.click()
      setDownloadSnack({ status: 'complete' })
      downloadSuccessSlackAlert({ apiUrl: exportUrl })
    } catch (err) {
      handleError(err.message)
      setDownloadSnack({ status: 'close' })
      downloadFailedSlackAlert({ err, apiUrl: exportUrl })
    }
  }, [exportUrl, filterArr, ...filterArr])

  const getTableData = React.useCallback(
    async (body: { limit?: number; offset: number }, callback: (data: TableDataType) => void) => {
      const { limit = 100, offset } = body
      setLoading(true)
      try {
        const urlInstance = new URL(url)
        urlInstance.searchParams.set('limit', limit.toString())
        urlInstance.searchParams.set('offset', offset.toString())
        if (orderBy) {
          urlInstance.searchParams.set('order_by', orderBy)
        }
        const res = await axios({
          url: urlInstance.href,
          method: apiMethod || 'GET',
          headers: {
            Authorization: `Bearer ${OpenAPI.TOKEN as string}`
          },
          data: {
            limit,
            offset,
            order_by: orderBy,
            ascending: ascending,
            ...filterBody
          }
        })
        callback(res.data)
        // setTableData(res.data)
      } catch (err) {
        openError(err.message)
      }
      setLoading(false)
    },
    [url, apiMethod, filterArr, orderBy, ascending, OpenAPI.TOKEN]
  )

  React.useEffect(() => {
    getTableData({ offset: 0 }, (data) => {
      setTableData(data)
    })
  }, [...filterArr, orderBy, ascending])

  return (
    <>
      <Page
        hideFilters={!showFilters}
        title={capitalize(title)}
        sx={{ p: 3, bgcolor: 'white' }}>
        <Box sx={{ mt: 2 }}>
          <CustomMaterialTable
            isLoading={loading}
            data={get(tableData, dataKey, [])}
            columns={columns}
            options={{
              stickyHeader: headerHeightPx,
              sortable: sortEnabled,
              showOrderBy: sortEnabled,
              initialOrderBy: ascending ? 'ascending' : 'descending',
              pagination: true,
              export: !!exportUrl,
              totalPaginatedDataLength: get(tableData, 'max_rows', undefined)
            }}
            onSortByChange={(obj) => {
              setOrderBy(obj.value)
            }}
            onOrderByChange={(orderBy) => {
              setAscending(orderBy === 'ascending')
            }}
            onLastPage={() => {
              if (get(tableData, 'next_offset', 0)) {
                getTableData({ offset: get(tableData, 'next_offset', 0) }, (data) => {
                  setTableData((prev) => {
                    return {
                      ...data,
                      [dataKey]: [...get(prev, dataKey, []), ...get(data, dataKey, [])]
                    }
                  })
                })
              }
            }}
            onExportCSV={() => {
              onExportCSV()
            }}
          />
        </Box>
      </Page>
    </>
  )
}

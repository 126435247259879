import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Divider, InputAdornment, List, Skeleton, TextField, Typography, alpha, useMediaQuery } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import CustomLink from 'src/layout/components/CustomLink'
import SubNav from 'src/layout/components/SubNav'
import HomePageLink from '../../HomePageLink'
import SideBarRefreshStatus from './SideBarRefreshStatus'
import SideBarWizardList from './SideBarWizardList'
interface SideBarRouteListProps {
  isSidebarOpen: boolean
  hideRefreshStatus?: boolean
  hideWizardList?: boolean
}
export default function SideBarRouteList(props: SideBarRouteListProps) {
  const { routeLoading, routes, categoryLoading, routeCategories, categoryOrder, open, setOpen, theme } = usePrivateLayoutDrawerContext()
  const [routeSearchText, setRouteSearchText] = React.useState('')
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const showRoute = (route, category = null) => {
    const lowerCaseSearchText = routeSearchText.toLowerCase()
    const matchingSearchText =
      category?.name?.toLowerCase().includes(lowerCaseSearchText) ||
      route.label?.toLowerCase().includes(lowerCaseSearchText) ||
      route.subNav?.some((subNav) => subNav.label?.toLowerCase().includes(lowerCaseSearchText))
    return route.showInSidebar && !route.hidden && matchingSearchText
  }
  const closeDrawer = () => {
    if (sm) setOpen(false)
  }
  const categorizedRoutes = React.useMemo(() => {
    const result = []
    const routesWithCategories = []
    categoryOrder.forEach((categoryUid) => {
      const category = routeCategories.find((item) => item.key === categoryUid)
      if (category) {
        const assignedRoutes = []
        category.routes.forEach((routeUid) => {
          const route = routes.find((item) => item.uid === routeUid)
          if (route && Object.keys(route).length > 0 && showRoute(route, category)) {
            assignedRoutes.push(route)
            routesWithCategories.push(route.uid)
          }
        })
        if (assignedRoutes.length)
          result.push({
            category: { ...category },
            routes: assignedRoutes,
            showInSidebar: category.showInSidebar
          })
      }
    })

    const routesWithoutCategories = routes.filter((route) => !routesWithCategories.includes(route.uid) && Object.keys(route).length > 0 && showRoute(route))
    if (routesWithoutCategories.length)
      result.push({
        category: null,
        routes: routesWithoutCategories,
        showInSidebar: true
      })
    return result
  }, [routeCategories, categoryOrder, routes, routeSearchText])
  return (
    <List
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '3px'
      }}>
      <Box
        height="100%"
        sx={{
          overflowX: 'hidden',
          overflowY: 'auto'
        }}
        pb={open ? 30 : 25}>
        {!props.hideWizardList && props.isSidebarOpen && <SideBarWizardList />}
        {props.isSidebarOpen && (
          <Box px="10px">
            <Box sx={{ mb: 2 }}>
              <TextField
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: '#091F2D',
                    color: '#FFF',
                    fontSize: '12px'
                  },
                  '& .MuiOutlinedInput-input': {
                    px: '13px',
                    py: '10px'
                  }
                }}
                placeholder="Search Module"
                value={routeSearchText}
                onChange={(e) => {
                  setRouteSearchText(e.target.value)
                }}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        m: 0,
                        color: '#FFF',
                        opacity: 0.3
                      }}>
                      <SearchIcon
                        sx={{
                          width: '16px',
                          height: '16px'
                        }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Divider
              sx={{
                bgcolor: '#FFF',
                opacity: 0.2
              }}
            />
          </Box>
        )}
        <Box my={2}>
          <HomePageLink
            open={open}
            closeDrawer={closeDrawer}
          />
        </Box>
        {(routeLoading || categoryLoading) &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <Skeleton
              variant="rounded"
              key={item}
              width={'90%'}
              height={40}
              sx={{
                mb: 1,
                mx: 'auto',
                bgcolor: alpha('#2F4A5A', 0.6)
              }}
              animation="wave"
            />
          ))}
        {!categoryLoading &&
          categorizedRoutes.map((category, index) => {
            if (!category.showInSidebar) return <></>

            return (
              <React.Fragment key={category.category ? category.category.key : index}>
                {props.isSidebarOpen && (
                  <Box sx={{ px: 2, mt: '26px', mb: '6px' }}>
                    <Typography sx={{ color: '#89949B', opacity: 0.5, fontWeight: 600, fontSize: '12px' }}>
                      {get(category, 'category.name', 'Others')}
                    </Typography>
                  </Box>
                )}
                {get(category, 'routes', []).map((navItem) => {
                  return navItem && navItem?.subNav && navItem?.subNav?.length ? (
                    <SubNav
                      key={navItem?.uid}
                      searchText={routeSearchText}
                      open={open}
                      closeDrawer={closeDrawer}
                      page={navItem}
                      type={navItem.type}
                      icon={navItem?.icon || <DocumentScannerIcon />}
                    />
                  ) : (
                    <CustomLink
                      key={navItem?.uid}
                      open={open}
                      closeDrawer={closeDrawer}
                      to={`/${navItem?.key}`}
                      label={get(navItem, 'label')}
                      icon={navItem?.icon}
                      isSubNav={false}
                      isLinkActive={false}
                      pageTitle={navItem?.pageTitle}
                      tooltip={navItem?.tooltip}
                      type={navItem.type}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        {!props.hideRefreshStatus && <SideBarRefreshStatus closeDrawer={closeDrawer} />}
      </Box>
    </List>
  )
}

import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone'
import { Icon, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import premium from 'src/assets/premium.svg'
import { useAuth } from 'src/context/AuthContext'
import PopupMenu from './PopupMenu'
import { activeColor, inactiveColor } from './SubNav'

type LinkProps = {
  icon: string | { label: string; value: string }
  to: string
  label: string
  open: boolean
  closeDrawer: () => void
  isSubNav: boolean
  isLinkActive: boolean
  pageTitle?: string
  tooltip?: string
  type?: string
}

const CustomLink: React.FC<LinkProps> = ({ icon, to, label, open, closeDrawer, isSubNav = 'false', isLinkActive = 'false', pageTitle, tooltip, type }) => {
  const href = to.includes('datastudio') ? `${window.location.origin}/${to.indexOf('datastudio')}` : to
  const { posthogCapture } = useAuth()
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement>(null)

  return (
    <NavLink
      to={href}
      onClick={() => {
        posthogCapture('sidebar_link_clicked', { href })
        closeDrawer()
      }}
      style={{ textDecoration: 'none' }}>
      {({ isActive }) => {
        if (isActive) document.title = pageTitle || `Loop - ${label}`
        return (
          <div
            onMouseLeave={() => {
              setAnchorElement(null)
            }}>
            <PopupMenu
              open={open}
              anchorElement={anchorElement}
              label={label}
              isActive={isActive}
            />
            <ListItem
              selected={isActive}
              disablePadding
              sx={{
                color: isActive ? activeColor : inactiveColor,
                '&.Mui-selected': { bgcolor: 'transparent' }
              }}>
              <ListItemButton
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  py: '6px',
                  bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
                  '&:hover': { bgcolor: 'rgba(25, 110, 130, 0.3)' }
                }}
                onMouseEnter={(e) => {
                  setAnchorElement(e.currentTarget)
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? '22px' : 'auto',
                    justifyContent: 'center',
                    color: 'inherit'
                  }}>
                  <Icon>{icon ? (typeof icon === 'string' ? icon : icon.value) : 'block'}</Icon>
                </ListItemIcon>
                {open && (
                  <>
                    <ListItemText
                      primary={label}
                      color="inherit"
                      sx={{
                        '& span': {
                          fontWeight: isActive && 600,
                          whiteSpace: 'normal',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }
                      }}
                    />
                    {type === 'preview' && (
                      <ListItemIcon
                        sx={{
                          minWidth: 24,
                          ml: 1, // Add some space between the text and the icon
                          color: 'inherit'
                        }}>
                        <img
                          src={premium}
                          alt="Connect to customer success team"
                          width={24}
                          height={24}
                        />
                      </ListItemIcon>
                    )}
                  </>
                )}
              </ListItemButton>
              {open && isSubNav && (isLinkActive ? <KeyboardArrowUpTwoToneIcon /> : <KeyboardArrowDownTwoToneIcon />)}
            </ListItem>
          </div>
        )
      }}
    </NavLink>
  )
}

export default CustomLink

import React from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { DefaultService } from 'src/services/openApi'
import { sendSlackNotification } from '../api'

export default function usePlaidAuth() {
  const [bankConnected, setBankConnected] = React.useState(false)
  const [linkToken, setLinkToken] = React.useState(null)
  const { currentUser } = useAuth()
  const { handleError } = useErrorData()

  // get current url
  const url = window.location.href.split('?')[0]

  const getAccessTokens = async () => {
    try {
      const response = await DefaultService.getAccessTokensPlaidGetAccessTokensPost(currentUser.org)
      return response['tokens']
    } catch (error) {
      handleError(error.message)
      return []
    }
  }

  const createLinkToken = async () => {
    try {
      return DefaultService.createLinkTokenPlaidCreateLinkTokenPost(url)
    } catch (error) {
      handleError(error.message)
    }
  }

  const handleOnSuccess = async (token, metadata) => {
    // Call the /item/public_token/exchange endpoint to exchange the public token for an access token and item id
    await DefaultService.setAccessTokenPlaidSetAccessTokenPost(token, currentUser.org, currentUser.uid)
    sendSlackNotification({
      message: `plaid integration status for ${currentUser.email} of ${currentUser.org}`,
      channel: 'fe-logs',
      title: 'Plaid integration status : Success'
    })

    setBankConnected(true)
  }

  React.useEffect(() => {
    try {
      getAccessTokens()
        .then((tokens) => {
          if (tokens.length !== 0) {
            setBankConnected(true)
            return
          }
          createLinkToken()
            .then((data) => {
              setLinkToken(data.link_token)
            })
            .catch((error) => handleError(error.message))
        })
        .catch((error) => handleError(error.message))
    } catch (error) {
      handleError(error.message)
    }
  }, [])

  const { open, ready, error } = usePlaidLink({
    clientName: 'Loop',
    env: 'production',
    product: ['auth', 'transactions'],
    token: linkToken!,
    onSuccess: handleOnSuccess,
    onExit: (error, metadata) => {
      sendSlackNotification({
        title: 'Plaid integration status : Failed',
        channel: 'frontend-alerts',
        message: `plaid integration status for ${currentUser.email} of ${currentUser.org}`
      })
    }
  })
  return {
    bankConnected,
    open,
    error,
    loading: !ready
  }
}

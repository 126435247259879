import { Box, Divider, Drawer, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { DefaultService, OrderContextModel } from 'src/services/openApi'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import PlatformLogo from '../PlatformLogo'
import BackArrowBtnComp from './components/BackArrowBtnComp'
import BreakdownComp from './components/BreakdownComp'
import ErrorChip from './components/ErrorChip'
import ItemsComp from './components/ItemsComp'
import NetPayoutComp from './components/NetPayoutComp'
import OrderId from './components/OrderId'
import OrderLink from './components/OrderLink'
import ReviewAndFeedback from './components/ReviewAndFeedback'
import TimelineComp from './components/TimelineComp'
import TransactionsComp from './components/TransactionsComp'

interface OrderDrawerProps {
  open: boolean
  orderId: string | null
  isDwt?: boolean | null
  onClose: () => void
}

export default function OrderDrawer(props: OrderDrawerProps) {
  const { open, orderId, onClose, isDwt } = props
  const { handleError } = useErrorData()
  const { currentUser } = useAuth()
  const [orderData, setOrderData] = React.useState<OrderContextModel | null>(null)
  const [loading, setLoading] = React.useState(true)

  const handleClose = () => {
    onClose()
    setOrderData(null)
    setTimeout(() => {
      setLoading(true)
    })
  }

  React.useEffect(() => {
    if (orderId && open) {
      const getOrderData = async () => {
        setLoading(true)
        try {
          const res = await DefaultService.orderDetailsOrderDetailsPost({
            order_id: orderId,
            is_dwt: isDwt
          })
          setOrderData(res)
        } catch (err) {
          handleError(err.message)
        } finally {
          setLoading(false)
        }
      }
      getOrderData()
    }
  }, [orderId, open])

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}>
        <Box sx={{ width: 'calc(100vw - 300px)', minWidth: '300px', minHeight: '100%' }}>
          {!loading && !orderData ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ p: '25px', minHeight: '100%' }}>
              <Typography
                fontSize="20px"
                fontWeight={600}>
                Order detail not available
              </Typography>
            </Stack>
          ) : (
            <Box sx={{ p: '25px' }}>
              <Stack
                direction="row"
                alignItems="flex-start"
                gap="20px">
                <BackArrowBtnComp onClick={handleClose} />
                <Box sx={{ flex: 1 }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="20px">
                    {loading ? (
                      <Skeleton
                        variant="rounded"
                        width="30px"
                        height="30px"
                      />
                    ) : (
                      orderData?.charges?.platform && (
                        <Box>
                          <PlatformLogo
                            shortImg
                            platformName={orderData?.charges?.platform}
                            width="30x"
                            height="30px"
                          />
                        </Box>
                      )
                    )}
                    {loading ? (
                      <Skeleton
                        variant="text"
                        sx={{ flex: 1 }}
                      />
                    ) : (
                      (orderData?.customer_name || orderData?.restaurant?.name) && (
                        <Tooltip
                          title={`${orderData?.customer_name || ''}${orderData.customer_name ? ' ordered' : 'Ordered'} from ${
                            orderData?.restaurant?.name || 'restaurant'
                          }`}>
                          <Typography
                            fontSize="24px"
                            color="#000"
                            sx={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span style={{ fontWeight: 700 }}>{orderData?.customer_name}</span>
                            {orderData?.customer_name ? ' ordered' : 'Ordered'} from <span style={{ fontWeight: 700 }}>{orderData?.restaurant?.name}</span>
                          </Typography>
                        </Tooltip>
                      )
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="9px"
                    flexWrap="wrap"
                    sx={{ mt: '10px' }}>
                    {(loading || orderData?.order_link) && (
                      <OrderLink
                        loading={loading}
                        link={orderData?.order_link}
                        platform={orderData?.charges?.platform}
                      />
                    )}
                    {(loading || orderData?.order_id) && !shouldBlockForUser(currentUser) && (
                      <OrderId
                        orderId={orderData?.order_id}
                        loading={loading}
                      />
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap="9px"
                    flexWrap="wrap"
                    sx={{ mt: '10px' }}>
                    {(loading || orderData?.error_category) && (
                      <ErrorChip
                        icon
                        error
                        category={orderData?.error_category}
                        loading={loading}
                      />
                    )}
                    {(loading || orderData?.error_subcategory) && (
                      <ErrorChip
                        correct={orderData?.error_subcategory?.toLowerCase() === 'no issue'}
                        category={orderData?.error_subcategory}
                        loading={loading}
                      />
                    )}
                  </Stack>
                </Box>
              </Stack>
              <Divider
                orientation="vertical"
                sx={{ mt: '10px' }}
              />
              <Stack
                direction="row"
                alignItems="stretch"
                gap="37px"
                flexWrap="wrap"
                sx={{ p: '25px', pl: '50px' }}>
                <Stack
                  direction="column"
                  alignItems="stretch"
                  gap={1}
                  sx={{ flex: 1 }}>
                  <TimelineComp
                    timelineData={orderData?.timeline}
                    loading={loading}
                    waitTimeData={orderData?.wait_time}
                    collapsable={isDwt}
                  />
                  <Divider />
                  <ReviewAndFeedback
                    type="review"
                    loading={loading}
                    review={orderData?.review}
                    platform={orderData?.charges?.platform}
                    collapsable={isDwt}
                  />
                  <Divider />
                  <ReviewAndFeedback
                    type="error_feedback"
                    loading={loading}
                    review={orderData?.error_feedback}
                    platform={orderData?.charges?.platform}
                    collapsable={isDwt}
                  />
                </Stack>
                <Divider
                  orientation="vertical"
                  flexItem
                />
                <Stack
                  direction="column"
                  alignItems="stretch"
                  gap={1}
                  sx={{ flex: 1 }}>
                  <ItemsComp
                    items={orderData?.items}
                    loading={loading}
                    collapsable={isDwt}
                  />
                  <Divider />
                  <BreakdownComp
                    charges={orderData?.charges}
                    loading={loading}
                    collapsable={isDwt}
                  />
                  <Divider />
                  <Stack
                    direction="row"
                    alignItems={'flex-start'}
                    justifyContent="space-between"
                    gap="70px">
                    <Box sx={{ flex: 1 }}>
                      <TransactionsComp
                        transactions={orderData?.charges?.transactions}
                        loading={loading}
                      />
                    </Box>
                    <NetPayoutComp
                      errorCategory={orderData?.error_category}
                      netPayout={orderData?.charges?.payout}
                      loading={loading}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  )
}

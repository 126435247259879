function getOrgRoutesInArrayList(orgRoutes) {
  return orgRoutes.map((route) => {
    if (typeof route === 'object') {
      return route.uid
    }
    return route
  })
}

function getRouteUidByKey(orgRoutes, key) {
  return orgRoutes.find((route) => route.key === key).uid
}

export { getOrgRoutesInArrayList, getRouteUidByKey }

import moment from 'moment'
import { useMemo } from 'react'
import { useAuth } from 'src/context/AuthContext'

export default function useCustomStartDate() {
  const { currentUser } = useAuth()
  const customStartDate = useMemo(() => {
    if (currentUser.org === 'whataburger') {
      return moment('2024-07-10')
    } else if (currentUser.org === 'awrg') {
      return moment('2024-07-01')
    }
    return null
  }, [currentUser])

  const customEndDate = useMemo(() => {
    // if (currentUser.org === 'whataburger') {
    //   return moment('2024-08-10')
    // }
    return null
  }, [currentUser])

  return { customStartDate, customEndDate }
}

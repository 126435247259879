import { Typo } from '@LoopKitchen/loop-ui'
import { Box } from '@mui/material'
import { get } from 'lodash'
import DataComp from 'src/pages/members/Finance/components/DataComp'
import { indicatorColor, rowFlex } from 'src/pages/members/Finance/utils/constants'
import { usePayoutsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/PayoutsBreakdownDataContext'
import { Modules } from 'src/utils/config/config'
import { Dictionary } from 'src/utils/config/dictionary'
import { OrderBreakdownResponse } from '../../../utils/types'

const BreakdownIndex = ({ label, data, oloExists }: { label: string; data: OrderBreakdownResponse; oloExists: boolean }) => {
  const { fontSizes, zoom } = usePayoutsBreakdownDataContext()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: '0 5px'
      }}>
      <Box
        sx={{
          borderRadius: '8px',
          height: zoom >= 67 ? '50px' : '60px',
          marginBottom: '10px',
          border: '1px solid #E6E6E6',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typo
          fs={fontSizes['14']}
          fw={400}
          sx={{ display: 'flex', justifyContent: 'center' }}>
          {label}
        </Typo>
      </Box>

      <DataComp
        sx={{ px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '35px' : '45px' },
            cols: [
              {
                label: 'Sales Incl. Tax',
                flex: rowFlex[2],
                sx: {
                  py: 0,
                  fontSize: fontSizes['14']
                },
                tooltip: 'Total revenue generated from orders, including taxes charged to customers.'
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '35px' : '45px' },
            cols: [
              {
                label: 'Sales Excl. Tax',
                flex: rowFlex[2],
                sx: {
                  py: 0,
                  fontSize: fontSizes['13'],
                  fontWeight: 400
                },
                tooltip: 'Total revenue generated from orders, excluding any taxes charged to customers.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.LostSales,
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: fontSizes['13'], fontWeight: 400 },
                tooltip: `Potential revenue lost due to ${Dictionary.CANCELLED.toLowerCase()} or failed orders.`
              }
            ]
          },
          {
            indicatorColor: indicatorColor.refunds,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.UnfulfilledRefunds,
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                },
                tooltip: 'Refunds due to order unfulfillment.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', px: '10px', py: '10px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.taxes,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Taxes',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                },
                tooltip: 'Taxes collected from customers and paid to you by 3P platforms.'
              }
            ]
          },
          {
            sx: {
              height: zoom >= 67 ? '40px' : '50px'
            },
            hideIndicator: true,
            cols: [
              {
                label: 'Taxes Withheld',
                flex: rowFlex[0],
                sx: { color: 'rgba(47, 74, 90, 0.6)', fontSize: fontSizes['13'], fontWeight: 400 },
                tooltip: 'Taxes collected from customers and held by 3P platforms to pay to tax authorities on your behalf.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.commissions,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Commissions',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                },
                tooltip: 'Fees charged by the platform for facilitating orders.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.restaurant_delivery_charge,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Rest. Delivery Charge',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                },
                tooltip: 'Charges collected from customers for restaurant delivery.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.marketing,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Marketing',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                },
                tooltip: 'Costs associated with promoting the restaurant on the platform.'
              }
            ]
          },
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.loyalty,
            sx: { borderTop: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            hide: !oloExists || get(data, 'values.loyalty', 0) === 0,
            cols: [
              {
                label: 'Loyalty',
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13']
                },
                flex: rowFlex[0],

                tooltip: 'Specific to Olo, this is the cost of the loyalty program.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.refunds,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.Chargebacks,
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                },
                tooltip: 'Refunds due to customer dissatisfaction.'
              }
            ]
          },
          {
            hideIndicator: true,
            indicatorColor: indicatorColor.reversals,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: Modules.WonDisputes,
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13']
                },
                flex: rowFlex[0],

                tooltip: `Includes won unfulfilled ${Modules.Chargebacks} disputes.`
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            indicatorColor: indicatorColor.unaccounted,
            sx: { borderBottom: '1px solid rgba(230, 230, 230, 1)', height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Others',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                }
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { mt: '15px', height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Customer Tip',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13']
                },
                tooltip: 'Additional amounts paid by customers as tips for service.'
              }
            ]
          },
          {
            hideIndicator: true,
            hide: !oloExists || get(data, 'values.restaurant_fees', 0) === 0,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Restaurant Fees',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13']
                },
                tooltip: 'Specific to Olo, this is the fees charged by the restaurant for facilitating orders.'
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Miscellaneous',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13']
                },
                tooltip: 'Various fees or charges that do not fit into specific categories.'
              }
            ]
          },
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '30px' : '40px' },
            cols: [
              {
                label: 'Unaccounted',
                flex: rowFlex[0],
                sx: {
                  color: 'rgba(47, 74, 90, 0.6)',
                  fontWeight: 400,
                  fontSize: fontSizes['13']
                },
                tooltip: 'Amounts that are not allocated or categorized, often requiring further investigation or clarification.'
              }
            ]
          }
        ]}
      />

      <DataComp
        sx={{ mt: '10px', py: '10px', px: '10px' }}
        rows={[
          {
            hideIndicator: true,
            sx: { height: zoom >= 67 ? '40px' : '50px' },
            cols: [
              {
                label: 'Estimated Payout',
                flex: rowFlex[0],
                sx: {
                  fontSize: fontSizes['13']
                }
              }
            ]
          }
        ]}
      />
    </Box>
  )
}

export default BreakdownIndex

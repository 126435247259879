import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import premium from 'src/assets/premium.svg'
import { useAuth } from 'src/context/AuthContext'
import { generateKey } from 'src/utils/functions'
import PopupMenu from './PopupMenu'

export const activeColor = '#00BDE9'
export const inactiveColor = '#FFF'

interface Page {
  key: string
  label: string
  icon: string
}

interface PageTypes extends Page {
  subNav: Page[]
}

function SubNav({
  page,
  open,
  closeDrawer,
  icon,
  parentKey,
  searchText,
  type
}: {
  open: boolean
  closeDrawer: () => void
  icon: string | { label: string; value: string }
  page: any
  parentKey?: string
  searchText?: string
  type?: string
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const [openMenu, setOpenMenu] = React.useState(false)
  const { posthogCapture } = useAuth()
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement>(null)

  const handleClick = () => {
    const parent = parentKey ? `/${parentKey}` : ''
    const tempSubNav = get(page, `subNav.[0].key.[0]`) === '/' ? get(page, `subNav.[0].key`) : `/${page.key}/${page.subNav[0].key}`
    const navigateLink = `${parent}${tempSubNav}`
    if (open === false) navigate(navigateLink)
    else setOpenMenu(!openMenu)
  }

  function getLink(page: PageTypes, index: number) {
    const parent = parentKey ? `/${parentKey}` : ''
    const tempSubNav = get(page, `subNav.${index}.key.[0]`) === '/' ? get(page, `subNav.${index}.key`) : `/${page.key}/${page.subNav[index].key}`
    const navigateLink = `${parent}${tempSubNav}`
    const href = navigateLink.includes('datastudio') ? `${window.location.origin}/${navigateLink.slice(navigateLink.indexOf('datastudio'))}` : navigateLink
    return href
  }

  React.useEffect(() => {
    if (open === false && openMenu === true) {
      setOpenMenu(false)
    }
  }, [open, openMenu])

  React.useEffect(() => {
    if (searchText.length > 0 && page.subNav.length > 0 && page.subNav.some((e) => e.label.toLowerCase().includes(searchText.toLowerCase()))) {
      setOpenMenu(true)
    } else {
      setOpenMenu(false)
    }
  }, [searchText, page])

  const checkIsActive = React.useCallback(
    (parentKey: string, subNavKey?: string) => {
      const key = generateKey(parentKey, subNavKey)
      return key === location.pathname
    },
    [location.pathname]
  )

  const isActive = React.useMemo(() => {
    if (Array.isArray(page.subNav) && page.subNav.length > 0) {
      return page.subNav.some((subPage) => checkIsActive(page.key, subPage.key))
    }
    return checkIsActive(page.key)
  }, [checkIsActive, location.pathname])

  const closeSubnavPopup = () => {
    setAnchorElement(null)
  }

  return (
    <div
      onMouseLeave={() => {
        closeSubnavPopup()
      }}>
      <PopupMenu
        hasSubnav
        page={page}
        open={open}
        anchorElement={anchorElement}
        closeSubnavPopup={closeSubnavPopup}
        getLink={getLink}
      />
      <List sx={{ py: 0 }}>
        <ListItem
          selected={isActive}
          disablePadding
          sx={{
            '&.Mui-selected': { bgcolor: 'transparent' }
          }}>
          <ListItemButton
            sx={{
              color: isActive ? activeColor : inactiveColor,
              bgcolor: isActive && !openMenu ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
              py: '6px',
              '&:hover': { bgcolor: 'rgba(25, 110, 130, 0.3)' }
            }}
            onClick={handleClick}
            onMouseEnter={(e) => {
              setAnchorElement(e.currentTarget)
            }}>
            <ListItemIcon sx={{ minWidth: 46, color: 'inherit' }}>
              <Icon>{typeof icon === 'string' ? icon : icon.value}</Icon>
            </ListItemIcon>
            <ListItemText
              primary={page.label}
              sx={{
                '& span': {
                  fontWeight: isActive && 600,
                  whiteSpace: 'normal',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }}
            />
            {openMenu ? <ExpandLess /> : <ExpandMore />}
            {type === 'preview' && (
              <ListItemIcon
                sx={{
                  minWidth: 24,
                  ml: 1, // Add some space between the text and the icon
                  color: 'inherit'
                }}>
                <img
                  src={premium}
                  alt="Connect to customer success team"
                  width={24}
                  height={24}
                />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        <Collapse
          in={openMenu}
          timeout="auto"
          unmountOnExit>
          <List
            component="div"
            sx={{ ml: 3, pl: -1, borderLeft: '3px solid #196E82', py: 0, my: 1 }}>
            {get(page, 'subNav', []).map((subPage, index) => {
              const isActive = checkIsActive(page.key, subPage.key)
              if (isActive) {
                document.title = subPage?.pageTitle || `Loop - ${page.label} - ${subPage.label}`
              }
              if (get(subPage, 'subNav', []).length > 0) {
                return (
                  <Box
                    pl={1}
                    key={subPage.key}>
                    <SubNav
                      open={openMenu}
                      closeDrawer={closeDrawer}
                      page={subPage}
                      parentKey={page.key}
                      icon={subPage.icon || <DocumentScannerIcon />}
                    />
                  </Box>
                )
              }
              return (
                <ListItemButton
                  sx={{
                    color: isActive ? activeColor : inactiveColor,
                    bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
                    '&:hover': { bgcolor: 'rgba(25, 110, 130, 0.3)' },
                    mx: 3,
                    pl: 1.5,
                    py: '6px',
                    borderRadius: '3px'
                  }}
                  component={Link}
                  onClick={() => {
                    posthogCapture('sidebar_link_clicked', { href: `${page.label}/${subPage.label}` })
                    closeDrawer()
                  }}
                  to={getLink(page, index)}
                  key={subPage.key}>
                  <ListItemText
                    primary={subPage.label}
                    sx={{ '& span': { fontWeight: isActive && 600 } }}
                  />
                </ListItemButton>
              )
            })}
          </List>
        </Collapse>
      </List>
    </div>
  )
}

export default SubNav

import { useErrorData } from 'src/context/ErrorContext'
import { useLoadingData } from 'src/context/LoadingContext'

export default function useAsyncWrapper() {
  const { setBackdropLoading } = useLoadingData()
  const { handleError } = useErrorData()

  async function asyncWrapper(fn: any) {
    setBackdropLoading(true)
    try {
      await fn
    } catch (error: any) {
      handleError(error.message)
    }
    setBackdropLoading(false)
  }

  return asyncWrapper
}
